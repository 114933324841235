<template>
  <!-- 开invoice -->
  <el-row>
    <el-col>
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span class="SubHead">开invoice</span>
          </div>
        </template>
        <el-form
          ref="invoiceFormRef"
          :rules="invoiceFormRules"
          :model="invoiceForm"
          label-width="100px"
          class="invoiceForm"
          style="padding-top: 15px;"
        >
          <el-form-item label="主体">
            <el-input v-model="userInfo.company" size="small" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="invoice抬头" prop="invoiceTitle">
            <el-input v-model="invoiceForm.invoiceTitle" size="small" clearable></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input
              v-model="invoiceForm.email"
              size="small"
              clearable
              :disabled="editEmail == 1"
              style="margin-right:10px;"
              @blur="changeEmail"
            ></el-input>
            <el-button type="text" @click="editEmailBtn" :disabled="!rightEmail" v-show="editEmail == 1"
              >修改</el-button
            >
          </el-form-item>
          <el-form-item label="邮箱验证码" prop="emailCode" v-show="emailCode">
            <el-input v-model="invoiceForm.emailCode" size="small" class="emailCode" clearable></el-input>
            <el-button type="primary" size="small" :disabled="!rightEmail" class="emailBtn" @click="getEmailCode">
              {{ countDownTime > 0 ? `已发送${countDownTime}s` : '发送邮箱验证码' }}
            </el-button>
          </el-form-item>
          <el-form-item label="业务类型">
            <el-radio v-model="invoiceForm.ToS" label="1" border size="small">Facebook</el-radio>
            <el-radio v-model="invoiceForm.ToS" label="3" border size="small">Google</el-radio>
            <el-radio v-model="invoiceForm.ToS" label="2" border size="small">TikTok</el-radio>
            <el-radio v-model="invoiceForm.ToS" label="9" border size="small">Bing</el-radio>
            <el-radio v-model="invoiceForm.ToS" label="10" border size="small">Yandex</el-radio>
            <el-radio v-model="invoiceForm.ToS" label="12" border size="small">Twitter</el-radio>
            <el-radio v-model="invoiceForm.ToS" label="13" border size="small">SnapChat</el-radio>
            <el-radio v-model="invoiceForm.ToS" label="14" border size="small">其他</el-radio>
            <el-input v-model="invoiceForm.businessType" size="small" class="radioInput" clearable></el-input>
          </el-form-item>
          <el-form-item label="银行">
            <el-radio v-model="invoiceForm.bank" label="渣打银行" border size="small">渣打银行</el-radio>
            <el-radio v-model="invoiceForm.bank" label="汇丰银行" border size="small">汇丰银行</el-radio>
            <el-radio v-model="invoiceForm.bank" label="花旗银行" border size="small">花旗银行</el-radio>
          </el-form-item>
          <el-form-item label="是否开票" class="checkAccount">
            <el-row align="middle" v-for="item in invoiceList" :key="item.id">
              <el-checkbox
                v-model="item.flag"
                label="开票请勾选"
                size="small"
                border
                @change="rowListChange(item)"
              ></el-checkbox>
              <div class="item">
                <label>收款账户</label>
                <el-input v-model="item.own_account" size="small" disabled></el-input>
              </div>
              <div class="item">
                <label>到账时间</label>
                <el-input v-model="item.invoice_date" size="small" disabled></el-input>
              </div>
              <div class="item">
                <label>收款可用金额</label>
                <el-input v-model="item.balance" size="small" disabled></el-input>
              </div>
              <div class="item">
                <label>已开票金额</label>
                <el-input v-model="item.cost" size="small" disabled></el-input>
              </div>
              <div class="item">
                <label>开票金额</label>
                <el-input v-model.trim="item.money" size="small" clearable @change="changeSum(item)"></el-input>
              </div>
            </el-row>
          </el-form-item>
          <el-form-item label="开票金额" prop="invoice_money">
            <el-input v-model="invoice_money" size="small" disabled></el-input>
          </el-form-item>
          <el-form-item label="手续费">
            <el-input v-model.trim="invoiceFee" size="small" clearable @change="changeFee"></el-input>
          </el-form-item>
          <el-form-item label="总金额">
            <el-input v-model="invoice_total" size="small" disabled></el-input>
          </el-form-item>
          <el-form-item>
            <el-button size="mini" type="primary" @click="submitInvoice">立即提交</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'Invoice',
  data() {
    return {
      userInfo: '',
      editEmail: 0,
      emailCode: true,
      invoiceForm: {
        invoiceTitle: '',
        email: '',
        emailCode: '',
        ToS: '1',
        businessType: '',
        bank: '渣打银行'
      },
      // 设置提交invoice表单验证规则
      invoiceFormRules: {
        invoiceTitle: [{ required: true, message: '请填写invoice抬头', trigger: 'blur' }],
        email: [
          { required: true, message: '请填写邮箱', trigger: 'blur' },
          {
            pattern: /(^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$)/,
            message: '请输入正确格式的邮箱号(输入后点击下方发送按钮并填写邮箱验证码)',
            trigger: 'change'
          }
        ]
      },
      // 邮箱验证码定时器
      countDownTime: 0,
      emailClock: null,
      // invoiceList: [
      //   {
      //     i_id: 1,
      //     flag: false,
      //     own_account: '',
      //     invoice_date: '',
      //     balance: '',
      //     cost: '',
      //     money: '',
      //   },
      // ],
      invoiceList: [],
      invoiceRow: [],
      // invoice每行选中的值
      invoiceRows: '',
      invoiceMoney: 0,
      invoiceFee: 0,
      invoiceTotal: 0
    }
  },
  computed: {
    rightEmail() {
      return /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(this.invoiceForm.email)
    },
    invoice_money: function() {
      var money = 0
      for (var i = 0; i < this.invoiceList.length; i++) {
        if (this.invoiceList[i].flag && this.invoiceList[i].money != '') {
          money += parseFloat(this.invoiceList[i].money)
        }
      }
      this.invoiceMoney = money
      return this.invoiceMoney.toFixed(3).slice(0, -1)
    },
    invoice_total: function() {
      this.invoiceTotal = parseFloat(this.invoiceMoney) + parseFloat(this.invoiceFee)
      return this.invoiceTotal.toFixed(3).slice(0, -1)
    }
  },
  mounted() {
    // 获取localStorage中的用户信息
    this.userInfo = this.$store.getters.userInfo
    // this.invoice_charge()
    this.getUserInfo()
    this.getInvoiceForm()
  },
  methods: {
    // 获取用户默认信息
    getUserInfo() {
      this.invoiceForm.invoiceTitle = this.userInfo.company

      if (this.userInfo.is_email == 1) {
        this.invoiceForm.email = this.userInfo.email
        this.emailCode = false
        this.editEmail = this.userInfo.is_email
      }
    },
    // 手续费保留2位小数
    // invoice_charge() {
    //   this.invoiceFee = parseInt(this.invoiceFee).toFixed(2)
    // },
    // 修改邮箱
    editEmailBtn() {
      this.editEmail = 0
      this.emailCode = true
    },
    changeEmail() {
      if (this.invoiceForm.email == this.userInfo.email && this.userInfo.is_email == 1) {
        this.emailCode = false
        this.editEmail = 1
      } else {
        this.emailCode = true
      }
    },
    // 获取邮箱验证码
    async getEmailCode() {
      if (!this.countDownTime) {
        // 启动倒计时
        this.countDownTime = 60
        this.emailClock = window.setInterval(() => {
          this.countDownTime--
          if (this.countDownTime <= 0) {
            clearInterval(this.emailClock)
          }
          this.content = this.countDownTime + 's后重新发送'
        }, 1000)
      }
      const { data: res } = await this.$http.post('edit_email', {
        token: this.userInfo.token,
        email: this.invoiceForm.email,
        type: 1
      })
    },
    // 获取表单数据
    async getInvoiceForm() {
      const { data: res } = await this.$http.post('invoice', {
        token: this.userInfo.token
      })
      this.invoiceList = res.invoice
    },
    changeFee() {
      if (this.invoiceFee < 0) {
        this.invoiceFee = 0
        return this.$message.error('手续费不能为负数')
      } else if (this.invoiceFee == '') {
        this.invoiceFee = 0
      }
    },
    // 开票金额限制并提示
    changeSum: function(items) {
      this.rowListChange(items)
      this.invoiceList.forEach(item => {
        var money = parseFloat(item.balance) - parseFloat(item.cost)
        if (item.money <= money != true) {
          item.money = item.money <= money ? item.money : money
          return this.$message.error('修改金额不能大于原始金额')
        } else if (item.money < 0) {
          item.money = 0
          return this.$message.error('金额不能为负数')
        } else if (item.money == '') {
          item.money = 0
        }
      })
    },
    rowListChange(item) {
      if (item.flag) {
        this.invoiceRow.push(item)
        var newArr = []
        for (var i = 0; i < this.invoiceRow.length; i++) {
          if (newArr.indexOf(this.invoiceRow[i]) === -1) {
            newArr.push(this.invoiceRow[i])
          }
        }
        this.invoiceRow = newArr
      } else {
        this.invoiceRow = this.invoiceRow.filter(i => i.i_id != item.i_id)
      }
      this.invoiceRows = this.invoiceRow.map(obj => obj.i_id + ',' + obj.money).join(';')
    },

    // 立即提交
    submitInvoice() {
      this.$refs.invoiceFormRef.validate(async vaild => {
        if (!vaild) return
        if (this.invoice_money <= 0) return this.$message.error('开票金额不能为 0')
        if (this.invoiceForm.ToS == '其他' && this.invoiceForm.businessType == '') {
          return this.$message.error('业务类型选择"其他"时，输入框内容不能为空')
        }
        const { data: res } = await this.$http.post('submit_invoice', {
          token: this.userInfo.token,
          bill_name: this.invoiceForm.invoiceTitle,
          email: this.invoiceForm.email,
          code: this.invoiceForm.emailCode,
          type_name: this.invoiceForm.businessType,
          type: this.invoiceForm.ToS,
          bank: this.invoiceForm.bank,
          money: this.invoiceRows,
          origin_amount: this.invoice_money,
          fee: this.invoiceFee,
          amount: this.invoice_total
        })
        if (res.code == 100) return this.$message.error(`${res.msg}`)
        if (res.code == 300) {
          this.$router.push('/login')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.invoiceForm {
  .el-form-item {
    .el-input {
      width: 400px;
    }
    .emailCode.el-input {
      width: 272px;
    }
    .radioInput.el-input {
      display: block;
      margin-top: 10px;
    }
    .emailBtn {
      width: 116px;
      margin-left: 10px;
    }
    .el-radio,
    .el-checkbox {
      margin-right: 10px;
    }
  }
  .checkAccount {
    .item {
      margin-right: 10px;
    }
    label {
      margin-right: 10px;
    }
    .el-input {
      width: 100px;
    }
  }
}
</style>
